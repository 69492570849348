/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitManualAdjustment = /* GraphQL */ `
  mutation SubmitManualAdjustment(
    $material: String
    $size: String
    $countryOfOrigin: String
    $quality: String
    $location: String
    $type: Type
    $quantity: Int
    $customsStatus: CustomsStatus
    $reason: String
    $comment: String
  ) {
    submitManualAdjustment(
      material: $material
      size: $size
      countryOfOrigin: $countryOfOrigin
      quality: $quality
      location: $location
      type: $type
      quantity: $quantity
      customsStatus: $customsStatus
      reason: $reason
      comment: $comment
    ) {
      message
      success
      timestamp
    }
  }
`;
