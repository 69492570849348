/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const transactionsDynamicSearch = /* GraphQL */ `
  query TransactionsDynamicSearch(
    $material: String!
    $size: String!
    $countryOfOrigin: String!
    $quality: String!
    $fromTimestamp: Float
    $toTimestamp: Float
    $type: String
    $location: String
    $customsStatus: String
    $reason: String
    $source: String
    $nextToken: String
  ) {
    transactionsDynamicSearch(
      material: $material
      size: $size
      countryOfOrigin: $countryOfOrigin
      quality: $quality
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      type: $type
      location: $location
      customsStatus: $customsStatus
      reason: $reason
      source: $source
      nextToken: $nextToken
    ) {
      items {
        id
        createdTimestamp
        commandId
        material
        size
        countryOfOrigin
        quality
        location
        transactionType
        action
        bondedQuantity
        nonBondedQuantity
        flowId
        businessReference
        source
        comment
        reason
      }
      nextToken
    }
  }
`;
export const inventoryDynamicSearch = /* GraphQL */ `
  query InventoryDynamicSearch(
    $material: String
    $size: String
    $countryOfOrigin: String
    $quality: String
    $imbalanceFilter: String
    $nextToken: String
  ) {
    inventoryDynamicSearch(
      material: $material
      size: $size
      countryOfOrigin: $countryOfOrigin
      quality: $quality
      imbalanceFilter: $imbalanceFilter
      nextToken: $nextToken
    ) {
      items {
        material
        size
        countryOfOrigin
        quality
        location
        bondedQuantity
        nonBondedQuantity
        snapshotDiff
        lastSuccessWmsSnapshotQuantity1060
        lastSuccessWmsSnapshotTimestamp1060
        lastSuccessWmsSnapshotQuantity1065
        lastSuccessWmsSnapshotTimestamp1065
        lastSuccessWmsSnapshotQuantity1067
        lastSuccessWmsSnapshotTimestamp1067
        lastSuccessWmsSnapshotQuantity1064
        lastSuccessWmsSnapshotTimestamp1064
        lastSuccessWmsSnapshotQuantity1264
        lastSuccessWmsSnapshotTimestamp1264
        lastSuccessLedgerSnapshotTimestamp
        lastSuccessLedgerSnapshotQuantity
        wmsSnapshotQuantity
        ledgerSnapshotQuantity
        ledgerSnapshotTimestamp
      }
      nextToken
    }
  }
`;
export const inventoryDetailSearch = /* GraphQL */ `
  query InventoryDetailSearch(
    $material: String!
    $size: String!
    $countryOfOrigin: String!
    $quality: String!
  ) {
    inventoryDetailSearch(
      material: $material
      size: $size
      countryOfOrigin: $countryOfOrigin
      quality: $quality
    ) {
      items {
        material
        size
        countryOfOrigin
        quality
        location
        bondedQuantity
        nonBondedQuantity
        snapshotDiff
        lastSuccessWmsSnapshotQuantity1060
        lastSuccessWmsSnapshotTimestamp1060
        lastSuccessWmsSnapshotQuantity1065
        lastSuccessWmsSnapshotTimestamp1065
        lastSuccessWmsSnapshotQuantity1067
        lastSuccessWmsSnapshotTimestamp1067
        lastSuccessWmsSnapshotQuantity1064
        lastSuccessWmsSnapshotTimestamp1064
        lastSuccessWmsSnapshotQuantity1264
        lastSuccessWmsSnapshotTimestamp1264
        lastSuccessLedgerSnapshotTimestamp
        lastSuccessLedgerSnapshotQuantity
        wmsSnapshotQuantity
        ledgerSnapshotQuantity
        ledgerSnapshotTimestamp
      }
      nextToken
    }
  }
`;
export const listTransactionsFieldSearch = /* GraphQL */ `
  query ListTransactionsFieldSearch($fieldName: String) {
    listTransactionsFieldSearch(fieldName: $fieldName) {
      items
    }
  }
`;
