import { useContext } from "react";
import useSWR from "swr";
import InventoryServiceContext from "../../providers/inventoryService-context";

const useAuditFieldListDistinctValues = (fieldName) => {
    const inventoryService = useContext(InventoryServiceContext);
    const {data, error} = useSWR(fieldName, (field) => inventoryService.listAuditFieldOptions(field));

    if (error) {
        console.log("Fetching field options for field " + fieldName + ", with as result: ", data, error);
    }

    if (data && data.data && data.data.listTransactionsFieldSearch) {
        return data.data.listTransactionsFieldSearch.items;
    }

    return data;
};

export default useAuditFieldListDistinctValues;